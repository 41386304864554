const menuTypes = {
  SET_OPEN_DATA_MODAL: 'SET_OPEN_DATA_MODAL',
  SET_OPEN_SETTINGS_MODAL: 'SET_OPEN_SETTINGS_MODAL',
  SET_OPEN_DEFINITIONS_MODAL: 'SET_OPEN_DEFINITIONS_MODAL',
  SET_OPEN_ABOUT_MODAL: 'SET_OPEN_ABOUT_MODAL',
  SET_OPEN_LEFT_SIDEBAR: 'SET_OPEN_LEFT_SIDEBAR',
  SET_OPEN_RIGHT_SIDEBAR: 'SET_OPEN_RIGHT_SIDEBAR',
  SET_CONCEPTS_FILTERS: 'SET_CONCEPTS_FILTERS',
  SET_RELEATIONS_FILTERS: 'SET_RELEATIONS_FILTERS',
  SET_CONSTRAINTS_FILTERS: 'SET_CONSTRAINTS_FILTERS',
  SET_QUESTIONS_FILTERS: 'SET_QUESTIONS_FILTERS',
  SET_MENU_INITIAL_STATE: 'SET_MENU_INITIAL_STATE',
};

export default menuTypes;
