import { useMemo } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectQuestions } from '../store/data/data.selectors';
import { usePagination } from '../hooks/usePagination';
import Pagination from './Pagination';

const Question = ({ question, answer }) => {
  return (
    <div className='flex flex-col w-full'>
      <p className='mr-2 mb-1'>{question}</p>
      <i className='mr-1 mb-2'>{answer}</i>
    </div>
  );
};

export default function Questions() {
  const questions = useSelector(selectQuestions);

  const { page, total, pageUp, pageDown, slice } = usePagination(
    questions,
    10
  );

  const questionsSlice = useMemo(() => {
    return (
      <div className='mb-2 max-h-full overflow-auto'>
        {slice.length > 0 &&
          slice.map((question) => (
            <Question key={question} question={question?.Question || ''} answer={question?.Answer || ''} />
          ))}
      </div>
    );
  }, [slice]);

  if (!questions.length) {
    return null;
  }

  return (
    <div className='flex flex-col h-full'>
      <div className='flex mb-3'>
        <b>Exploratory questions</b>
      </div>
      {questionsSlice}
      {questions.length > 10 && <Pagination
        page={page}
        total={total}
        pageUp={pageUp}
        pageDown={pageDown}
      />}
    </div>
  );
}
